import ApiService from "@/services/pamisDashboardApi.service";
import mockdatasitegetdatefilters from "@/mockdata/sitegetdatefilters.json"
import mockdatasiteproductionquantity from "@/mockdata/productionReportsData.json"
import i18n from '@/libs/i18n'

const getDefaultState = () => {
    return {
        siteWorkcenterProductionData: null,
        dateFilterId: 2,
    };
};
const CancelAllRequests = () => {
    ApiService.abortRequests();
};
const ResetProdStates = (state) => {
    state.siteWorkcenterProductionData = null;
};
export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        GetDateFilters(state) {
            return state.dateFilters;
        },
        GetDateFilterId(state) {
            return state.dateFilterId;
        },
        GetProductionReportsData(state) {
            return state.siteWorkcenterProductionData;
        },
    },
    actions: {

        GetProductionReportsData(ctx, param) {
            debugger;

            let data;
            param = { siteId: 100 }
            if (param.siteId == 100)
                data = mockdatasiteproductionquantity.plastic;
            else
                data = mockdatasiteproductionquantity.ic;

            var pt7 = data.datasource.find(x => x.name == "L201");
            var pt9 = data.datasource.find(x => x.name == "L202");
            var pt11 = data.datasource.find(x => x.name == "L203");
            var pt12 = data.datasource.find(x => x.name == "L204");
            var pt13 = data.datasource.find(x => x.name == "L205");
            var l101 = data.datasource.find(x => x.name == "L101");
            var l102 = data.datasource.find(x => x.name == "L102");

            if (param.dateFilterId == 1) {
                if (pt7 != undefined) {
                    pt7.ppm = 31326
                    pt7.oee = 80
                    pt9.ppm = 15659
                    pt9.oee = 68
                    pt11.ppm = 28326
                    pt11.oee = 81
                    pt12.ppm = 0
                    pt12.oee = 0
                    pt13.ppm = 32346
                    pt13.oee = 79
                }

                if (l101 != undefined) {
                    l101.ppm = 36324
                    l101.oee = 85
                    l102.ppm = 25668
                    l102.oee = 76
                }
            } else if (param.dateFilterId == 2) {
                if (pt7 != undefined) {
                    pt7.ppm = 24326
                    pt7.oee = 82
                    pt9.ppm = 28659
                    pt9.oee = 65
                    pt11.ppm = 24436
                    pt11.oee = 82
                    pt12.ppm = 0
                    pt12.oee = 0
                    pt13.ppm = 22118
                    pt13.oee = 78
                }

                if (l101 != undefined) {
                    l101.ppm = 24467
                    l101.oee = 90
                    l102.ppm = 28744
                    l102.oee = 78
                }
            } else if (param.dateFilterId == 3) {
                if (pt7 != undefined) {
                    pt7.ppm = 21226
                    pt7.oee = 85
                    pt9.ppm = 19659
                    pt9.oee = 60
                    pt11.ppm = 24436
                    pt11.oee = 85
                    pt12.ppm = 0
                    pt12.oee = 0
                    pt13.ppm = 26118
                    pt13.oee = 79
                }

                if (l101 != undefined) {
                    l101.ppm = 29443
                    l101.oee = 86
                    l102.ppm = 26112
                    l102.oee = 66
                }
            } else if (param.dateFilterId == 4) {
                if (pt7 != undefined) {
                    pt7.ppm = 18224
                    pt7.oee = 86
                    pt9.ppm = 25651
                    pt9.oee = 69
                    pt11.ppm = 28432
                    pt11.oee = 82
                    pt12.ppm = 0
                    pt12.oee = 0
                    pt13.ppm = 21122
                    pt13.oee = 72
                }

                if (l101 != undefined) {
                    l101.ppm = 32441
                    l101.oee = 85
                    l102.ppm = 19112
                    l102.oee = 65
                }
            } else if (param.dateFilterId == 10) {
                if (pt7 != undefined) {
                    pt7.ppm = 35234
                    pt7.oee = 81
                    pt9.ppm = 28616
                    pt9.oee = 67
                    pt11.ppm = 24343
                    pt11.oee = 86
                    pt12.ppm = 0
                    pt12.oee = 0
                    pt13.ppm = 21118
                    pt13.oee = 78
                }

                if (l101 != undefined) {
                    l101.ppm = 25449
                    l101.oee = 88
                    l102.ppm = 32112
                    l102.oee = 68
                }
            } else if (param.dateFilterId == 11) {
                if (pt7 != undefined) {
                    pt7.ppm = 21226
                    pt7.oee = 80
                    pt9.ppm = 19659
                    pt9.oee = 65
                    pt11.ppm = 23436
                    pt11.oee = 88
                    pt12.ppm = 0
                    pt12.oee = 0
                    pt13.ppm = 26118
                    pt13.oee = 78
                }

                if (l101 != undefined) {
                    l101.ppm = 28445
                    l101.oee = 90
                    l102.ppm = 29112
                    l102.oee = 82
                }
            } else if (param.dateFilterId == 12) {
                if (pt7 != undefined) {
                    pt7.ppm = 21226
                    pt7.oee = 83
                    pt9.ppm = 19659
                    pt9.oee = 62
                    pt11.ppm = 24436
                    pt11.oee = 80
                    pt12.ppm = 0
                    pt12.oee = 0
                    pt13.ppm = 26118
                    pt13.oee = 79
                }

                if (l101 != undefined) {
                    l101.ppm = 25447
                    l101.oee = 90
                    l102.ppm = 32112
                    l102.oee = 68
                }
            }

            ctx.commit("SetSiteWorkcenterProductionData", { ...data, context: 'workcenters production info' });
            // return new Promise((resolve, reject) => {
            //   ApiService.query("ProductionData/Site/ProductionQuantity", {
            //     siteId: param.siteId,
            //     dateFilterId: param.dateFilterId,
            //   })
            //     .then((response) => {
            //       ctx.commit("SetSiteWorkcenterProductionData", { ...response.data, context: 'workcenters production info' });
            //       resolve(response.data);
            //     })
            //     .catch((error) => {
            //       ctx.commit("SetSiteWorkcenterProductionData", {
            //         isError: true,
            //         context: error,
            //       });
            //       reject(error);
            //     });
            // });
        },
        GetDateFilters(ctx) {
            let data = mockdatasitegetdatefilters;


            ctx.commit("SetDateFilters", data);
            // return new Promise((resolve, reject) => {
            //   ApiService.get("Utility/Site", "GetDateFilters")
            //     .then((response) => {
            //       ctx.commit("SetDateFilters", response.data);
            //       resolve(response.data);
            //     })
            //     .catch((error) => {
            //       ctx.commit("SetDateFilters", null);
            //       reject(error);
            //     });
            // });
        },
        SetDateFilterId(ctx, newId) {
            ctx.commit("SetDateFilterId", newId);
        },
        RESET_STATE(ctx) {
            ctx.commit("SET_RESET_STATE");
        },
    },
    mutations: {
        SET_RESET_STATE(state) {
            CancelAllRequests();
            Object.assign(state, getDefaultState());
        },
        SetSiteBreakdownRate(state, breakdownRate) {
            state.breakdownRate = breakdownRate;
        },
        SetSiteBreakdown(state, breakdown) {
            state.breakdown = breakdown;
        },
        SetSiteSummaryInfo(state, summaryInfo) {
            state.siteSummaryInfo = summaryInfo;
        },
        SetDateFilters(state, filters) {
            state.dateFilters = filters;
        },
        SetDateFilterId(state, newDateFilterId) {
            CancelAllRequests();
            ResetProdStates(state);
            state.dateFilterId = newDateFilterId;
        },
        SetSiteId(state, siteId) {
            state.siteId = siteId;
        },
        SetSiteWorkcenterProductionData(state, productionQuantity) {
            state.siteWorkcenterProductionData = productionQuantity;
        },
    },
};
