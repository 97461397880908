<template>
  <div id="controlchart">
    <b-card>
      <highcharts
        :constructor-type="'chart'"
        style="margin-left: clamp(2px, 2vw, 5px); padding-left: 0px"
        :options="options"
      />
    </b-card>
  </div>
</template>
<script>
import { BCard, BRow, BCol, BButton } from "bootstrap-vue";
import { Chart } from "highcharts-vue";
import gradientColors from "@/assets/widget/highchartGradientColorPalettes.json";
import exporting from "highcharts/modules/exporting";
import Highcharts from "highcharts";
exporting(Highcharts);
export default {
  props: {
    data: null,
  },
  components: {
    Highcharts: Chart,
    BCard,
  },

  data() {
    return {
      options: {
        colors: [
          gradientColors.blue,
          gradientColors.green,
          gradientColors.orange,
          gradientColors.purple,
          gradientColors.turquoise,
          gradientColors.yellow,
          gradientColors.olivedrab,
          gradientColors.red,
          gradientColors.olivedrab,
        ],
        credits: {
          enabled: false,
        },
        scrollbar: { enabled: false },
        rangeSelector: {
          enabled: false,
          selected: 4,
          inputEnabled: false,
          buttonTheme: {
            visibility: "hidden",
          },
          allButtonsEnabled: false,
          labelStyle: {
            visibility: "hidden",
          },
        },
        navigator: {
          visible: false,
          enabled: false,
        },
        chart: {
          type: "column",
        },
        yAxis: [
          {
            title: "",
            min: 0,
          },
        ],
        legend: {
          enabled: true,
          shadow: false,
        },
        tooltip: {
          shared: true,
        },
        title: {
          text: "",
        },

        xAxis: {
          type: "category",
          categories: ["Brazil", "Turkey", "USA", "China"],
        },
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500,
              },
              chartOptions: {
                legend: {
                  align: "center",
                  verticalAlign: "bottom",
                  layout: "horizontal",
                },
                yAxis: {
                  left: 20,
                  labels: {
                    x: 5,
                    y: -8,
                  },
                  title: {
                    text: "",
                  },
                },
                xAxis: {},
              },
            },
          ],
        },
        series: [
          {
            name: "Total Quantity",
            color: "rgba(165,170,255,1)",
            data: [150, 73, 20, 120],
          },
          {
            name: "NOKQuantity",
            color: "rgba(90,86,134,1)",
            data: [140, 90, 40, 10],
          },
        ],
        ...this.data,
      },
    };
  },
  methods: {
    increaseValue() {
      this.options.series[0].data[3] += 10;
      let updatedOptions = JSON.parse(JSON.stringify(this.options));
      this.options.series = updatedOptions.series;
    },
  },
};
</script>
<style scoped lang="scss">
@import "./assets/styling/charts.scss";
</style>
