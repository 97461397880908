<template>
  <div>
    <div class="date-filter-wrapper">
      <span>
        <feather-icon
          style="align-items: center; margin-left: 10px"
          icon="CalendarIcon"
          size="1.1x"
      /></span>
      <span>
        <b-form-select
          id="country-date-select"
          @change="onDateSelectChange"
          style="width: max-content; border: 0px"
          v-model="selectedDateFormat"
          :options="selectOptions"
        />
      </span>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from "@vue/composition-api";
import { BRow, BCol, BForm, BFormSelect } from "bootstrap-vue";
import store from "@/store";
import i18n from "@/libs/i18n";
export default {
  name: "DateFilter",
  components: {
    BCol,
    BForm,

    BFormSelect,
    BRow,
  },
  setup() {
    const selectedDateFormat = ref(
      store.getters["pamis-production-reports-module/GetDateFilterId"]
    );

    store.dispatch("pamis-production-reports-module/GetDateFilters");
    const selectOptions = computed(() => {
      const dropdownList =
        store.getters["pamis-production-reports-module/GetDateFilters"];
      dropdownList.forEach((e) => {
        e.text = i18n.t(e.text);
      });
      return dropdownList;
    });

    watch(selectOptions, (newValue) => {
      if (newValue) {
        setDateFilter();
      }
    });

    function onDateSelectChange(param) {
      setDateFilter();
      store.dispatch("pamis-production-reports-module/SetDateFilterId", param);
    }

    function setDateFilter() {
      store.dispatch("pamis/GetDateFilter", {
        value: selectedDateFormat.value,
        text: selectOptions.value.find(
          (x) => x.value == selectedDateFormat.value
        ).text,
      });
    }

    return {
      selectOptions,
      selectedDateFormat,
      onDateSelectChange,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s ease-out;
}

.date-filter-wrapper {
  max-width: 190px;
  background-color: #fff;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
