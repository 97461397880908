<template>
  <div>
    <b-card>
      <b-row>
        <b-col lg="2" class="my-1">
          <b-form-group label-size="m" label="Country">
            <v-select
              id="countryDropdown"
              v-model="selectedCountry"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="code"
              :options="countryDropdownOptions"
            >
            </v-select>
          </b-form-group>
        </b-col>
        <b-col lg="2" class="my-1">
          <b-form-group label-size="m" label="Site">
            <v-select
              id="siteDropdown"
              v-model="selectedSite"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="code"
              :options="siteDropdownOptions"
            >
            </v-select>
          </b-form-group>
        </b-col>
        <b-col lg="2" class="my-1">
          <b-form-group label-size="m" label="Workcenter">
            <v-select
              id="workcenterDropdown"
              v-model="selectedWorkcenter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="code"
              :options="workcenterDropdownOptions"
            >
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="2" class="my-1">
          <b-form-group label-size="m" label="Date">
            <DateFilter /> </b-form-group
        ></b-col>
        <b-col
          cols="2"
          class="d-flex align-items-center justify-content-start mt-1"
        >
          <b-button variant="success" @click="GetData">GET</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col> </b-col>
      </b-row>
    </b-card>

    <b-row>
      <b-col><widget :widgetData="finalProductionQuantityOptions" /></b-col>
      <b-col><widget :widgetData="allWorkcentersPPMOptions" /></b-col
    ></b-row>
    <b-row>
      <b-col><widget :widgetData="finalKpiOptions" /></b-col>
      <b-col><widget :widgetData="finalBreakdownRateOptions" /></b-col
    ></b-row>
    <b-row></b-row>
  </div>
</template>
<script>
import { BCard, BRow, BCol, BFormGroup, BButton } from "bootstrap-vue";

import vSelect from "vue-select";
import useProductionReportsData from "./components/useProductionReportsChart.js";
import { ref } from "@vue/composition-api";
import store from "@/store";
import productionReportsStoreModule from "./productionReportsStoreModule.js";
import Widget from "./components/widgets/Widget.vue";
import DateFilter from "./components/DateFilter.vue";
export default {
  components: {
    BCard,
    BFormGroup,
    vSelect,
    BButton,
    BRow,
    DateFilter,
    Widget,
    BCol,
  },
  setup() {
    const PAMIS_MODULE_NAME = "pamis-production-reports-module";
    const countryDropdownOptions = ref([
      "All",
      "Turkey",
      "Brazil",
      "United States",
      "China",
    ]);
    const selectedCountry = ref("Turkey");
    const siteDropdownOptions = ref(["All", "IC Plant", "Plastic"]);
    const selectedSite = ref("Plastic");
    const workcenterDropdownOptions = ref(["All", "PT7", "PT8"]);
    const selectedWorkcenter = ref("PT7");

    if (!store.hasModule(PAMIS_MODULE_NAME))
      store.registerModule(PAMIS_MODULE_NAME, productionReportsStoreModule);
    store.dispatch("pamis-production-reports-module/GetDateFilters");
    store.dispatch("pamis-production-reports-module/GetProductionReportsData");

    const {
      finalProductionQuantityOptions,
      allWorkcentersPPMOptions,
      finalKpiOptions,
      checkGroupStatus,
      finalBreakdownRateOptions,
    } = useProductionReportsData();
    const GetData = () => {};
    return {
      siteDropdownOptions,
      selectedSite,
      countryDropdownOptions,
      selectedCountry,
      workcenterDropdownOptions,
      selectedWorkcenter,
      finalProductionQuantityOptions,
      finalBreakdownRateOptions,
      allWorkcentersPPMOptions,
      finalKpiOptions,
      checkGroupStatus,
      GetData,
    };
  },
};
</script>
<style scoped>
.table-card-title {
  margin-bottom: clamp(5px, 2vw, 10px);
}
.click-table:hover {
  cursor: pointer;
}
</style>
  <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

#add-new-job-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>

  