<template>
  <div>
    <LoadingScreen
      v-show="widgetData.response_status === 'pending'"
      context="Workcenter Charts"
    />
    <ErrorNoData
      v-show="widgetData.response_status === 'error'"
      :context="widgetData.context"
    />
    <WarningNoData
      v-show="widgetData.response_status === 'no_data'"
      :context="widgetData.context"
    />
    <b-row v-show="isEditing">
      <b-col>
        <div class="editor-bar">
          <feather-icon
            icon="MoveIcon"
            style="
              color: rgba(170, 170, 170, 0.5);
              margin: 5px;
              margin-right: 10px;
              float: right;
            "
            width="15px"
            height="15px"
          />
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        ><component
          :key="JSON.stringify(this.$i18n.locale)"
          :is="widgetData.widgetType"
          :data="widgetData"
          class="widget-component"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import HighchartColumn from "./chart-widgets/HighchartColumn.vue";
import NumericTarget from "@/views/pamis/components/widgets/numeric-widgets/NumericTarget.vue";
//import NumericNormal from "./numeric-widgets/NumericNormal.vue";
//import NumericStatus from "@/views/pamis/components/widgets/numeric-widgets/NumericStatus.vue";
import ErrorNoData from "@/views/miscellaneous/ErrorNoData.vue";
import LoadingScreen from "@/views/pamis/components/LoadingScreen.vue";
import WarningNoData from "@/views/miscellaneous/WarningNoData.vue";
import { BRow, BCol } from "bootstrap-vue";
import { ref, inject } from "@vue/composition-api";
export default {
  props: {
    widgetData: null,
  },
  components: {
    HighchartColumn,
    NumericTarget,
    BRow,
    BCol,
    ErrorNoData,
    LoadingScreen,
    WarningNoData,
  },
  setup(props) {
    const isEditing = inject("isEditing");
    return {
      isEditing,
    };
  },
};
//there will be multiple instances of normal bars
</script>
<style scoped>
.editor-bar {
  bottom: 5px;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 1);
  border: 1px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1px 2s.6px !important;
}
.widget-component {
  box-shadow: rgba(0, 0, 0, 0.075) 6.95px 6.95px 7.6px !important;
}
</style>
